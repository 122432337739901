export const popup = () => {
  jQuery(".button_popup").click(function () {
    jQuery(".overlay").fadeIn();
    jQuery("body").addClass("body-class");
  });

  jQuery(".close-popup").click(function () {
    jQuery(".overlay").fadeOut();
    jQuery("body").removeClass("body-class");
  });

  jQuery(document).mouseup(function (e) {
    var popup = jQuery(".popup");
    if (e.target != popup[0] && popup.has(e.target).length === 0) {
      jQuery(".overlay").fadeOut();
      jQuery("body").removeClass("body-class");
    }
  });

  jQuery(document).keydown(function (e) {
    if (e.which === 27) {
      jQuery(".overlay").fadeOut();
      jQuery("body").removeClass("body-class");
    }
  });
};
