export const swiper = () => {
  var swiper = new Swiper(".swiper", {
    effect: "coverflow",
    // grabCursor: true,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 3,
      slideShadows: true,
    },
    // keyboard: {
    //   enabled: true,
    // },
    // mousewheel: {
    //   thresholdDelta: 70,
    // },
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 5000, // Кожні 3 секунди
      disableOnInteraction: false, // Не вимикається при взаємодії
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      390: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  });
};
