export const industrial = () => {
  const nameItems = document.querySelectorAll(".name_item_industrial");
  const contentItems = document.querySelectorAll(".content_item_industrial");
  const contentItemse = document.querySelectorAll(".content_itemes");
  contentItems.forEach((contentItem) => {
    contentItem.style.display = "none";
  });
  contentItemse.forEach((contentItem) => {
    contentItem.style.display = "none";
  });
  nameItems[0].classList.add("active");
  contentItems[0].style.display = "block";
  contentItemse[0].style.display = "block";

  nameItems.forEach((nameItem, index) => {
    nameItem.addEventListener("click", () => {
      contentItems.forEach((contentItem) => {
        contentItem.style.display = "none";
      });
      contentItemse.forEach((contentItem) => {
        contentItem.style.display = "none";
      });

      nameItems.forEach((item) => {
        item.classList.remove("active");
      });

      contentItems[index].style.display = "block";
      nameItem.classList.add("active");
      contentItemse[index].style.display = "block";
      nameItem.classList.add("active");
    });
  });
};
