export const faq = () => {
  const questionTitles = document.querySelectorAll(
    ".faq_items .question_title"
  );
  questionTitles.forEach((title, index) => {
    title.addEventListener("click", () => {
      if (title.parentElement.classList.contains("open")) {
        title.parentElement.classList.remove("open");
      } else {
        // Видалення класу "open" з попереднього відкритого питання
        const openQuestion = document.querySelector(".faq_items.open");
        if (openQuestion) {
          openQuestion.classList.remove("open");
        }

        title.parentElement.classList.add("open");
      }
    });
  });
};
