export const certificat = () => {
  $(document).ready(function () {
    var modal = $("#myModal");
    var modalImg = $("#modalImage");
    var imgClickables = $(".gallery-image");

    imgClickables.click(function () {
      var imgSrc = $(this).attr("src");
      modalImg.attr("src", imgSrc);
      modal.css("display", "block");
      $("body").addClass("gallery");
    });

    $(".close").click(function () {
      modal.css("display", "none");
      $("body").removeClass("gallery");
    });

    modal.click(function (event) {
      if (event.target === modal[0]) {
        modal.css("display", "none");
        $("body").removeClass("gallery");
      }
    });
  });
};
