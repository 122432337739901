import { burger } from "./modules/header/burger.js";
import { modals } from "./modules/modals.js";
import { garage } from "./modules/garage.js";
import { certificat } from "./modules/certificat.js";
import { industrial } from "./modules/industrial.js";
import { automatics } from "./modules/automatics.js";
import { automatics_industrial } from "./modules/automatics_industrial.js";
import { faq } from "./modules/faq.js";
import { popup } from "./modules/popup.js";
// import { faq_auto_industrial } from "./modules/faq_auto_industrial.js";
import { swiper } from "./modules/swiper.js";
document.addEventListener("DOMContentLoaded", function (event) {
  burger();
  modals();
  swiper();
  garage();
  industrial();
  automatics();
  popup();
  automatics_industrial();
  faq();
  certificat();
  // faq_auto_industrial();
});
